import React from 'react';


export function Image(props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & { fallback: string; name?: string; }) {
    function handleFallback(e: any) {
        e.target.onError = null;
        e.target.src = props.fallback;
    }

    return <img {...props} onError={handleFallback} />;
}
