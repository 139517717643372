import { gql } from '@apollo/client';

export interface GetCardSeriesVariables {
  type: string;
}

export const getCardSeriesQuery = {
  query: gql`
    query CardCollection($type: CardType, $searchTerm: String) {
      cardCollection(type: $type, searchTerm: $searchTerm) {
        cardSeries {
          name
        }
        cardSet {
          name
        }
      }
    }
  `,
  variables: {
    type: 'POKEMON',
    searchTerm: undefined,
  },
};


export const cardAutoCompleteQuery = {
  query: gql`
    query CardAutoCompleteQuery($type: CardType, $searchTerm: String) {
      cardCollection(type: $type, searchTerm: $searchTerm) {
        cardAutoComplete {
          name
        }
      }
    }
  `,
  variables: {
    type: 'POKEMON',
    searchTerm: undefined,
  },
};

export const cardInsightDetailsQuery = {
  query: gql`
    query CardInsightDetailsQuery($type: CardType, $cardId: String) {
      cards(type: $type, cardId: $cardId) {
        cards {
          listings {
            numFound
          }
          id
          cardId
          name
          number
          series
          set
          rarity
          elementType
          cardType
          releaseDate
          setCode
          illustrator
          abilityName1
          abilityDescription1
          abilityName2
          abilityDescription2
          weaknessType
          weaknessAmount
          resistType
          resistAmount
          evolutionStage
          hitPoints
          Pokemon
          imageSrc
        }
      }
    }
  `,
  variables: {
    type: 'POKEMON',
    cardId: '',
  },
};


export const cardInsightSearchQuery = {
  query: gql`
    query CardInsightSearchQuery($type: CardType, $searchTerm: String, $grade: Int, $sortBy: String, $offset: Int) {
      cards(type: $type, searchTerm: $searchTerm, sortBy: $sortBy, offset: $offset) {
        numFound
        numFoundExact
        start
        cards {
          id
          name
          series
          set
          imageSrc
          cardId
          rarity
          number
          marketAnalysis(grade: $grade) {
            card_id
            month_date
            sold_price_median_3_roll
            card_grade
          }
          marketSummary(grade: $grade) {
            product_key
            card_grade
            sold_price_high_current_months
            sold_price_low_current_months
            sold_price_median_current_months
            growth_rate_median_all_months
          }
        }
      }
    }
  `,
  variables: {
    type: 'POKEMON',
    searchTerm: undefined,
    sortyBy: 'releaseDate desc'
  },
};



export const cardSeriesSetQuery = {
  query: gql`
 query SeriesSetQuery(
  $type: CardType
  $searchTerm: String
  $offset: Int
  $filter: [SolrFilter]
) {
  cardCollection(
    type: $type
    searchTerm: $searchTerm
    offset: $offset
    filter: $filter
  ) {
    cardSeries {
      name
      sets {
        name
      }
    }
    
  }
}
  `,
  variables: {
    searchTerm: '',
    offset: 0,
    type: 'POKEMON',
  },
};


export const cardGetCardsBySeriesQuery = {
  query: gql`
query Cards($type: CardType, $filter: [SolrFilter]) {
  cardCollection(type: $type, filter: $filter) {
    cards {
      imageSrc   
      cardId
    }
  }
}
`,
  variables: {
    type: 'POKEMON',
    filter: [],
  },
};


