import React, { useEffect } from 'react';
// import classes from '../../styles/card-grading.module.scss';
import { useState } from 'react';
import { IOption } from '../../components/shared/AutoComplete';
import { ISubgrade } from '../../models/create-listing/ISubgrade';
import { Grade } from '../../models/create-listing/Grade';
import { Instruction } from '../../components/shared/Instruction';
import Slider from '@mui/material/Slider';
import { ToggleButtonGroup } from '../../components/shared/ToggleButtonGroup';
import { gradesList } from '../../data/grades';
import { flatMapSearch } from '../../utils/utilities';

export function CardGradingMobile(props: {
    onGradeSelected?: (grade: Grade) => void;
    onSubgradeSelected: (key: string, value?: number) => void;
    onHasSubGradesSelected?: (hasSubGrades: boolean) => void;
    selectedGrade?: Grade;
    selectedGradingCompany?: string;
    selectedSubgrade?: ISubgrade;
    hasSubgrades?: boolean;
}) {
    const [selectedSliderGrade, setSelectedSliderGrade] = React.useState<number>(+(props.selectedGrade?.value ?? 1));

    useEffect(() => {
        setSelectedSliderGrade((props.selectedGrade?.value || 1) as number);

    }, [props.selectedGrade]);

    const [subgrades, setSubgrades] = useState<ISubgrade | undefined>(props.selectedSubgrade);
    const onHasSubgradeSelected = (hasSubGrades: boolean) => {
        props.onHasSubGradesSelected?.(hasSubGrades);
    };

    const onSubgradeSelected = (key: string, value: number) => {
        const sGrades = { ...subgrades, [key]: value };
        setSubgrades(sGrades);
        props.onSubgradeSelected(key, value);
    };

    const onGradeSelected = (grade: Grade) => {
        const newG = { ...grade, children: undefined };
        props.onGradeSelected?.(newG);
    };



    const findGrade = (grade: number, company: string) => {

        // const item = recursiveSearch(gradesList, 'children', (i) => {
        //     return i.value === grade && i.company === company;
        // });

        const item = flatMapSearch<Grade>(gradesList, 'children', (i) => {
            return i?.value === grade && i?.company === company;
        });

        return item;
    };

    const onGradeSliderChanged = (event: any, newValue: any) => {

        if (!newValue) return;

        setSelectedSliderGrade(newValue);

        if (props.selectedGradingCompany) {
            const grade = findGrade(newValue, props.selectedGradingCompany) as Grade;
            if (grade) {
                onGradeSelected(grade);
                props.onGradeSelected?.(grade);

            } else {
                throw new Error('Grade not found');
            }

        }
    };

    function sliderValueText(value: number) {
        return `${value}`;
    }

    return <>
        <Instruction text="Select your grade/subgrade" color="white" italic={false} />
        <Slider
            defaultValue={1}
            getAriaValueText={sliderValueText}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            value={selectedSliderGrade}
            onChange={onGradeSliderChanged}
            step={0.5}
            marks
            min={1}
            max={10}
        />
        {props.selectedGrade && <>
            <Instruction text="Does your graded card have subgrades?" color="white" italic={false} />
            <ToggleButtonGroup
                aria-labelledby="condition-options"
                aria-orientation="horizontal"
                value={`${props.hasSubgrades}`}
                onChange={(e) => onHasSubgradeSelected(e.target.value === 'true')}
                options={[
                    { id: 'yes', value: 'true', label: 'Yes' } as IOption,
                    { id: 'no', value: 'false', label: 'No' } as IOption
                ]}
            />
        </>}

        {props.hasSubgrades && <>
            <Instruction text="select the assigned subgrade" italic={true} color={'FFFFFF40'} />
            <div>
                <label id="discrete-slider-centering-label" htmlFor='discrete-slider-centering'>Centering</label>
                <Slider
                    id="discrete-slider-centering"
                    defaultValue={1}
                    getAriaValueText={sliderValueText}
                    aria-labelledby="discrete-slider-centering-label"
                    valueLabelDisplay="auto"
                    value={subgrades?.Centering ?? 1}
                    onChange={(e, v) => onSubgradeSelected('Centering', v as number)}
                    step={0.5}
                    marks
                    min={1}
                    max={10}
                />
                <label id='discrete-slider-surface-label' htmlFor='discrete-slider-surface'>Surface</label>
                <Slider
                    id="discrete-slider-surface"
                    defaultValue={1}
                    getAriaValueText={sliderValueText}
                    aria-labelledby="discrete-slider-surface-label"
                    valueLabelDisplay="auto"
                    value={+(subgrades?.['Surface'] ?? 1)}
                    onChange={(e, v) => onSubgradeSelected('Surface', v as number)}
                    step={0.5}
                    marks
                    min={1}
                    max={10}
                />
                <label id='discrete-slider-corners-label ' htmlFor='discrete-slider-corners'>Corners</label>
                <Slider
                    id="discrete-slider-corners"
                    defaultValue={1}
                    getAriaValueText={sliderValueText}
                    aria-labelledby="discrete-slider-corners-label"
                    valueLabelDisplay="auto"
                    value={+(subgrades?.['Corners'] ?? 1)}
                    onChange={(e, v) => onSubgradeSelected('Corners', v as number)}
                    step={0.5}
                    marks
                    min={1}
                    max={10}
                />
                <label id='discrete-slider-edges-label' htmlFor='discrete-slider-edges'>Edges</label>
                <Slider
                    id="discrete-slider-edges"
                    defaultValue={1}
                    getAriaValueText={sliderValueText}
                    aria-labelledby="discrete-slider-edges-label"
                    valueLabelDisplay="auto"
                    value={+(subgrades?.['Edges'] ?? 1)}
                    onChange={(e, v) => onSubgradeSelected('Edges', v as number)}
                    step={0.5}
                    marks
                    min={1}
                    max={10}
                />
            </div>
        </>}
    </>;

}
