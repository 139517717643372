
import React, { useCallback } from 'react';
import { Section } from './Section';
import classes from '../../styles/cardCondition.module.scss';
import { RainbowChips } from '../../components/RainbowChips';
import { CardGradingCompany } from './CardGradingCompany';
import { ActiveRadioGroup } from '../../components/shared/RadioButton';
import { CardGrading } from './CardGrading';


import { selfAssessmentGrades } from '../../data/grades';
import { IOption } from '../../components/shared/AutoComplete';

import { ConditionType } from './models/ConditionType';
import { ICardConditionProps } from './CardConditionMobile';

const conditionTypeOptions = [
  { value: ConditionType.Graded, label: ConditionType.Graded, id: ConditionType.Graded, count: 0 },
  { value: ConditionType.Ungraded, label: ConditionType.Ungraded, id: ConditionType.Ungraded, count: 0 }
];

const selfAssesmentOptions = selfAssessmentGrades.map(i => ({ id: `${i.value}`, label: i.title, value: i.value, count: 0, description: i.description }));

export function CardCondition(props: ICardConditionProps) {

  const onSubgradeSelected = (key: string, option?: IOption) => {
    const sGrades = { ...props.selectedSubgrades, [key]: option?.value };
    props.onSubgradeSelected?.(sGrades);
  };

  const onHasSubgradesSelected = (hasSubgrades: boolean) => {
    props.onHasSubGradesSelected?.(hasSubgrades);
  };

  const onSelfAssessmentGradeSelected = (grade: IOption) => {
    const cg = selfAssessmentGrades.find(i => i.value === grade.value);

    if (!cg)
      throw 'a suitable selfassessment grade cannot be found';

    props.onCardGradeSelected?.(cg);
  };

  const Condition = useCallback(() => {
    switch (props.selectedCardCondition) {
      case ConditionType.Graded:
        return <>
          <CardGradingCompany onSelected={props.onGradingCompanySelected} selected={props.selectedGradingCompany} onOtherSet={props.onOtherGradingCompanySelected} other={props.selectedOtherGradingCompanyName} />
          <CardGrading
            selectedGradingCompany={props.selectedGradingCompany}
            selectedGrade={props.selectedCardGrade}
            onGradeSelected={props.onCardGradeSelected}
            selectedSubgrade={props.selectedSubgrades}
            hasSubgrades={props.selectedHasSubgrades}
            onSubgradeSelected={onSubgradeSelected}
            onHasSubGradesSelected={onHasSubgradesSelected} />
        </>;
      case ConditionType.Ungraded:
        return <RainbowChips
          className={classes.rainbowChips}
          onSelected={onSelfAssessmentGradeSelected}
          selected={{
            id: `${props.selectedCardGrade?.value}`,
            label: props.selectedCardGrade?.title || '',
            value: props.selectedCardGrade?.value || '',
            count: 0,
          }}
          options={selfAssesmentOptions} />;
      default: return <></>;
    }
  }, [props.selectedCardCondition, props.selectedGradingCompany, props.selectedCardGrade, props.selectedSubgrades, props.selectedSubgrades, props.selectedHasSubgrades]);

  return (
    <Section
      title="Card Condition"
      subTitle="Condition Type"
      className={classes.container}
    >
      <div className={classes.subheading2}>
        Select one of the two condition types
      </div>
      <ActiveRadioGroup
        row
        aria-labelledby="condition-options"
        aria-orientation="horizontal"
        name="condition"
        value={props.selectedCardCondition}
        onChange={(event) => {
          props.onConditionSelected?.(event.target.value as ConditionType);
        }}
        options={conditionTypeOptions}
      />

      <Condition />
    </Section >
  );
}
