/* eslint-disable no-console */
import React, { useMemo, useRef, useState } from 'react';
import styles from '../styles/App.module.scss';
import classes from '../styles/marketplace.module.scss';
import { Main } from '../components/Main';
import { direction, useScrollDirection } from '../hooks/useScrollDirection';

import { InsightsSearchBar } from 'src/components/SearchBar/InsightsSearchBar';
import { useAppSelector } from '../hook';
import { QuickLinks } from './MandatedLinks';
import { ChevronLeft, Search } from '@mui/icons-material';
import { useMedia } from 'react-use';
import { IconButton } from '@mui/material';
import { useMobileFooterBar } from './useMobileFooterBar';
import { selectCardSearchParameters } from 'src/features/cardSearch/cardSearchSelectors';


export function InsightsCenter(props: {
  children?: JSX.Element;
  hideSearchBar?: boolean;
}) {
  const [autoHideSearchBar, setAutoHideSearhcBox] = useState<boolean>(true);
  const selectSearchTerm = useAppSelector(selectCardSearchParameters);
  const searchResultRef = useRef(window);
  // const numberOfListingsFound = useAppSelector((state) => state.cardSearch.cardSearchResults.length ?? 10);
  const isMobile = useMedia('(max-width: 799px)');
  const [showMobileListingSearch, setShowMobileListingSearch] = useState<boolean>(false);

  const {
    // setShowActionBar,
    // showActionBar,
    MobileFooterBar
  } = useMobileFooterBar();


  // const showSlidingActionBar = () => {
  //   setShowActionBar(!showActionBar);
  // };


  useScrollDirection((e: direction) => {
    switch (e) {
      case 'SCROLL_DIRECTION_DOWN': {
        setAutoHideSearhcBox(false);
        break;
      }
      case 'SCROLL_DIRECTION_UP': {
        setAutoHideSearhcBox(true);
        break;
      }
    }
  }, searchResultRef);


  const MobileDefaultHeaderBar = () => (<>
    <div className={classes.title} tabIndex={-1}>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <a aria-label='link' href="/" style={{ display: 'flex' }}><img src="/static/media/logo.59c3e8d1873c6bf23e932fcc2cf43e1b.svg" alt="logo" height="40" /></a>
        Marketplace
      </span>
      <IconButton className={classes.searchButton} onClick={() => setShowMobileListingSearch(true)}>
        <Search style={{}} />
      </IconButton>
    </div>
  </>
  );

  const SearchCardsHeaderBar = () => (<>
    <div className={classes.staticContent}>
      <IconButton className={classes.back} onClick={() => setShowMobileListingSearch(false)}>
        <ChevronLeft />
        Insights
      </IconButton>
      <div className={classes.searchListingsTitle} tabIndex={-1}>
        Search Cards
      </div>
      <div style={{ width: 100, userSelect: 'none' }}></div>
    </div>
    <InsightsSearchBar
      value={selectSearchTerm?.autoCompleteSearchString}
      onSelected={(value) => { console.log(value); }}
      className={`${styles.searchBar} `}

    />
  </>);

  const ActiveMobileHeader = useMemo(() => {
    return isMobile && showMobileListingSearch ? <SearchCardsHeaderBar /> : <MobileDefaultHeaderBar />;
  }, [isMobile, showMobileListingSearch]);


  return (
    <Main>
      <div className={styles.main} id='main-insight-center'>
        <div className={classes.mainContent}>
          {/* <NumberOfListings count={numberOfListingsFound} hide={props?.hideSearchBar} /> */}
          {props?.hideSearchBar !== true && isMobile ?
            <div className={`${classes.searchBarContainer} ${autoHideSearchBar === false ? classes.hideSearchBar : ''} ${classes.hideFullSearchBar}`}>
              <div className={classes.mobileSearchBar}>
                {ActiveMobileHeader}
              </div>
            </div> : <>
            </>}
          <div className={styles.searchResults}>
            {props.children}
          </div>

        </div>
        <QuickLinks />
        {MobileFooterBar}

      </div>
    </Main >
  );
}

export function NumberOfListings(props: { count: number, hide?: boolean; }) {
  return props.count > 0 && !props.hide ? <div style={{ padding: '0 10px 20px 10px', color: 'lightgray' }}>Found {props.count} listings</div> : <></>;
}



