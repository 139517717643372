
import { ReCaptchaAction } from '../../helpers/recaptcha';
import { cards } from '../service-config';
import { postAsync } from '../baseService';
import { IndexSearchResponse } from '../IAZSearch';
import { ISearchParameters } from '../search-service/ISearchParameters';
import { apolloClient } from '../graph/baseService';
import { cardInsightDetailsQuery, cardInsightSearchQuery } from 'src/data/gql/queries/cardQueries';
import { ICardPagedResults } from 'src/features/insights/IPagedResults';

interface ICardServices {
    cardSearchService: (params: ISearchParameters) => any;
    getCardByIdService: (cardId: string) => any;
    cardAutoCompleteService: (searchQuery: string, filterString?: string) => any;
    getInsightCardListService: (params: ISearchParameters) => Promise<ICardPagedResults | undefined>;
    getInsightCardDetailsService: (cardId: string) => Promise<any | undefined>;
    // cardSearchService: (params: ISearchParameters) => Promise<IndexSearchResponse | undefined>;
    // getCardByIdService: (cardId: string) => Promise<IndexSearchResponse | undefined>;
    // cardAutoCompleteService: (searchQuery: string, filterString?: string) => Promise<IndexSearchResponse | undefined>;
    // getInsightCardListService: (params: ISearchParameters) => Promise<IPagedResults<ISimpleCard> | undefined>;
    // getInsightCardDetailsService: (cardId: string) => Promise<any | undefined>;
}




const services: ICardServices = {
    cardSearchService: (params: ISearchParameters) => {
        try {
            return postAsync<IndexSearchResponse, any>(
                cards.searchCardsUrl,
                null,
                {
                    x_q_re: btoa(params.query),
                    x_q_f: btoa(params.queryFilter)
                },
                ReCaptchaAction.api_card_search
            ) as Promise<IndexSearchResponse | undefined>;
        } catch (er) {
            // eslint-disable-next-line no-console
            console.error(er);
            return Promise.resolve(undefined);
        }

    },
    getCardByIdService: (cardId: string) => {
        try {
            return postAsync<IndexSearchResponse, any>(
                cards.searchCardsUrl,
                null,
                {
                    x_q_re: btoa(`cardId:${cardId}` || '*:*'),
                },
                ReCaptchaAction.api_card_search
            ) as Promise<IndexSearchResponse | undefined>;
        } catch (er) {
            // eslint-disable-next-line no-console
            console.error(er);
            return Promise.resolve(undefined);
        }
    },
    cardAutoCompleteService: async (searchQuery: string, filterString?: string) => {
        try {

            return postAsync<IndexSearchResponse, any>(
                cards.cardAutocompleteURL,
                null,
                {
                    x_q_re: btoa(searchQuery || '*:*'),
                    x_q_f: btoa(filterString || '*:*')
                },
                ReCaptchaAction.api_card_search
            ) as Promise<IndexSearchResponse | undefined>;

        } catch (er) {
            // eslint-disable-next-line no-console
            console.error(er);
            return Promise.resolve(undefined);
        }
    },
    getInsightCardListService: async (params: ISearchParameters) => {
        try {
            const { data } = await apolloClient.query({
                query: cardInsightSearchQuery.query,
                variables: {
                    type: 'POKEMON',
                    searchTerm: params.query,
                    grade: 0,
                    offset: params.currentPage * 20,
                }
            });
            return data;
        } catch (er) {
            // eslint-disable-next-line no-console
            console.error(er);
        }

    },
    getInsightCardDetailsService: async (cardId: string) => {
        try {

            const { data } = await apolloClient.query({
                query: cardInsightDetailsQuery.query,
                variables: {
                    type: 'POKEMON',
                    cardId: cardId
                }
            });
            return data.cards.cards[0];
        } catch (er) {
            // eslint-disable-next-line no-console
            console.error(er);
        }

    }
};


export const {
    getCardByIdService,
    cardSearchService,
    cardAutoCompleteService,
    getInsightCardListService,
    getInsightCardDetailsService
} = services;