import classes from './../../styles/createListing.module.scss';

import React, { useCallback } from 'react';
import { StepperButtons } from '../../features/createListing/StepperButtons';
import { Outlet } from 'react-router-dom';
import { SelectedCardsPreview } from '../../features/createListing/SelectedCardsPreview';
import { HeaderBar } from '../../components/HeaderBar';
import styles from '../../styles/App.module.scss';
import { useMedia } from 'react-use';
import { stateConfigV2 } from '../../data/stateMachine/stateConfigV2';
import { useCreateListing } from './useCreateListing';
import { LoadingWidget } from '../../components/shared/Loading';

const listingSteps = ['search', 'listing-type'];

export default function CreateListing() {
  const {
    handleStep,
    handleNext,
    handleBack,
    currentListing,
    isLoading,
    state,
  } = useCreateListing();

  const isMobile = useMedia('(max-width: 1000px)');

  const stepperLabel = useCallback((label: string, index: number) => {

    if (isMobile)
      return index.toString();
    else
      return label;
  }, [isMobile]);


  return (
    <>
      <HeaderBar className={styles.headerBar} />

      <div className={classes.root}>
        <form
          noValidate
          autoComplete="off"
          className={classes.form}
          onSubmit={(event) => {
            event.preventDefault();
          }}
        >
          <div className={classes.stepContent}>
            {
              !listingSteps.includes(state?.name as string) && (
                <SelectedCardsPreview
                  listing={currentListing}
                />
              )}
            <LoadingWidget isLoading={isLoading} style={{ width: '100%', height: '100%' }}>
              <Outlet />
            </LoadingWidget>
          </div>
        </form>
        <div className={classes.stepperButtonContainer}>
          <StepperButtons
            previousProps={{
              onClick: handleBack,
              isHidden: state?.name === 'listing-type',
              label: 'Back',
            }}
            nextProps={{
              onClick: handleNext,
              label: 'Continue',
              disabled: !state?.validate?.(currentListing),
            }}
          >
            <div className={classes.stepper}>
              {stateConfigV2 &&
                Object.keys(stateConfigV2).map((st, index) => (
                  <React.Fragment key={index}>
                    <a
                      className={
                        state?.name == st ? classes.selectedStep : ''
                      }
                      key={index}
                      onClick={() => handleStep(stateConfigV2[st].name as string)}
                    >
                      {stepperLabel(stateConfigV2[st].displayName ?? '', index)}
                    </a>
                    {(isMobile && (index < Object.keys(stateConfigV2).length - 1)) && <div className={classes.stepLine}></div>}
                  </React.Fragment >
                ))}
            </div>
          </StepperButtons>
        </div>
      </div>
    </>
  );
}


