import React from 'react';
import classes from './../../styles/card-grading-company.module.scss';
import {
  FormControlLabel,
  Radio,

} from '@mui/material';
import { useEffect, useState } from 'react';

import { TextField } from '../../components/shared/TextField';
import { ActiveRadioGroup } from '../../components/shared/RadioButton';
import { IOption } from '../../components/shared/AutoComplete';

export interface GraderOptionType { id: string, value: string, label: string; }

export const graders: Array<IOption> = [
  {
    id: 'psa',
    value: 'psa',
    label: 'PSA',
    count: 0
  },
  {
    id: 'bgs',
    value: 'bgs',
    label: 'BGS',
    count: 0
  },
  {
    id: 'cgc',
    value: 'cgc',
    label: 'CGC',
    count: 0
  },

];

export function CardGradingCompany(props: {
  orientation?: 'horizontal' | 'vertical';
  onSelected?: (value: any) => void;
  selected?: string;
  onOtherSet?: (value: string) => void;
  other?: string;
}) {

  const [ selected, setSelected ] = useState<string>(props.selected || '');
  const [ other, setOther ] = useState<string>(props.other || '');

  const onSetOther = (value: string) => {
    if (value === 'other' && selected !== 'other') {
      setSelected('other');
    }
    setSelected('other');
    setOther(value);
    props.onOtherSet ?.(value);
  };

  useEffect(() => {
    if (selected !== 'other') {
      setOther('');
      props.onOtherSet ?.('');
    }
  }, [ props.selected ]);

  return (
    <>
      <div className={classes.title}>Grading Company</div>
      <div className={classes.instruction}>Please select the associated grading company</div>
      <ActiveRadioGroup
        row
        aria-labelledby="grading-company-options"
        aria-orientation="horizontal"
        name="gradingCompany"
        value={selected}

        className={classes.gradingCompany}
        onChange={(event) => {
          setSelected(event.target.value);
          props.onSelected ?.(event.target.value);
        }}
        options={graders}
      >
        <FormControlLabel
          value="other"
          style={{ color: 'white' }}
          control={<Radio required style={{ color: selected === 'other' ? '#98FFF9' : '#FFFFFF40' }} />}
          className="subtitle1"
          label={<TextField
            title="Other"
            variant='outlined'
            color="secondary"
            placeholder="Other"
            label="Other"
            value={other}
            onChange={(event: any) => onSetOther(event.target.value)} />}
        />
      </ActiveRadioGroup >
    </>
  );
}
