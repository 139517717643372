import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { error, warn } from '../../../actions/logActions';
import { getSellerListingsService, getSellerSoldListingsService, reportListingService, reportUserService } from '../../../services/listing-service/listing-service';
import { IGraphSearchResult } from '../../../services/search-service/SolrSearchResults';
import { IRegistrationLink } from '../../../models/user/iRegistrationLinks';
import { getOnboardingStatusService } from '../../../services/payment-service/payment-service';
import { debounce } from 'lodash';
import { updateMidService } from 'src/services/user-service/userService';
import { IListing } from 'src/models/create-listing/iListing';



export interface ISellerListingsRequest {
  userId: string;
}

interface IReportParameters {
  listingId?: string,
  reason: string;
}

interface ISellerActions {
  getSellerListings: AsyncThunk<any, { page: number; userId: string; }, any>;
  getSellerSoldListings: AsyncThunk<any, { page: number; }, any>;
  getSellerPaymentServiceOnboardingStatus: AsyncThunk<IRegistrationLink, string, any>;
  reportSeller: AsyncThunk<void, IReportParameters, any>;
  reportListing: AsyncThunk<void, IReportParameters, any>;
  updateSellerMId: AsyncThunk<void, { mId: string; userId: string; }, any>;
}

const getOnboardingStatus = debounce(async (authToken, thunkApi) => {
  let response;
  try {
    response = await getOnboardingStatusService(authToken);
    if (!response?.err) {
      return response.result;
    }
    warn('GET_SELLER_PAYMENT_STATUS_ERROR', response.err);

    return thunkApi.rejectWithValue('no onboarding status');
  } catch (ex: any) {
    error('GET_SELLER_PAYMENT_STATUS_ERROR', { errorMessage: ex.message });
    return thunkApi.rejectWithValue(ex.message ?? ex);
  }
}, 300, { leading: true, trailing: false });

const actions: ISellerActions = {
  updateSellerMId: createAsyncThunk('api/updateSellerMId', async (param: { mId: string, userId: string; }, thunkapi: any) => {
    try {
      await updateMidService({ mId: param.mId, userId: param.userId });

    } catch (ex: any) {
      error('UPDATE_SELLER_MERCHANT_ID_ERROR', { errorMessage: ex.message });
      return thunkapi.rejectWithValue(ex.message ?? ex);
    }
  }),
  reportSeller: createAsyncThunk('api/reportSeller', async (report: IReportParameters, thunkapi: any) => {
    try {
      if (!report.listingId) {
        throw new Error('No listing id provided');
      }
      const response = await reportUserService(report.listingId, report.reason);
      if (!response?.err) {
        return response.result;
      }
      return thunkapi.rejectWithValue(response.err ?? 'We had a problem reporting the user please try again later');
    } catch (ex: any) {
      error('REPORT_SELLER_ERROR', { errorMessage: ex.message });
      return thunkapi.rejectWithValue(ex.message ?? ex);
    }
  }),
  reportListing: createAsyncThunk('api/reportListing', async (report: IReportParameters, thunkapi: any) => {
    try {
      if (!report.listingId) {
        throw new Error('No listing id provided');
      }
      const response = await reportListingService(report.listingId, report.reason);
      if (!response?.err) {
        return response.result;
      }

      return thunkapi.rejectWithValue(response.err ?? 'We had a problem reporting this listing. Please try again later.');
    } catch (ex: any) {
      error('REPORT_LISTING_ERROR', { errorMessage: ex.message });
      return thunkapi.rejectWithValue(ex.message ?? ex);
    }
  }),
  getSellerPaymentServiceOnboardingStatus: createAsyncThunk('api/sellerPaymentOnboardingStatus', async (authToken: string, thunkapi) => {
    return getOnboardingStatus(authToken, thunkapi);

  }),
  getSellerListings: createAsyncThunk('api/sellerListings', async (args: { page: number; userId: string; }, thunkapi: any) => {
    try {

      const response = await getSellerListingsService(args.page, args.userId);

      if (!response?.err) {
        const returnResult: IGraphSearchResult = {

          records: response.data.listings.listings,
          totalRecords: response?.data?.listings.numFound || 0,
          start: response?.data?.listings.start || 0,
        };

        return returnResult;
      }

      if (!response) {
        return thunkapi.rejectWithValue('Error creating item order');
      }
      return response.err;
    } catch (ex: any) {
      error('USER_GET_SELLER_LISTINGS_ERROR', { errorMessage: ex.message });
      return thunkapi.rejectWithValue(ex.message ?? ex);
    }
  }),
  getSellerSoldListings: createAsyncThunk('api/sellerSoldListings', async (args: { page: number; }, thunkApi: any) => {
    try {

      const response = await getSellerSoldListingsService(args.page);

      if (!response?.err) {
        const returnResult: IGraphSearchResult<IListing> = {
          records: response.data.listings.listings,
          totalRecords: response?.data?.listings.numFound || 0,
          start: response?.data?.listings.start || 0,
        };

        return returnResult;
      }

      if (response.err) {
        return thunkApi.rejectWithValue(response.err);
      } else {
        return response.data.cardCollection[0].listings;
      }


    } catch (ex: any) {
      error('USER_GET_SELLER_LISTINGS_ERROR', { errorMessage: ex.message });
      return thunkApi.rejectWithValue(ex.message ?? ex);
    }
  }),
};

export const {
  getSellerPaymentServiceOnboardingStatus,
  getSellerListings,
  getSellerSoldListings,
  reportSeller,
  reportListing,
  updateSellerMId,

} = actions;
