import classes from './../../styles/selected-card-preview.module.scss';

import React from 'react';
import { IListing } from '../../models/create-listing/iListing';
import { RemoteImage } from '../cardSearch/RemoteImage';
import ImageToBeUploaded from '../../assets/svg/image-to-be-uploaded.svg';

export function SelectedCardsPreview(props: { listing?: IListing; }) {
    const { listing } = props;


    const frontImage = listing?.images?.front?.path
        ? `${process.env.REACT_APP_IMAGES_URL}${listing?.images?.front.path} `
        : ImageToBeUploaded;

    return <div className={classes.selectedCardsPreview}>
        <div className={classes.contents}>
            <div className={classes.thumbnail}>
                <img src={frontImage} alt="selected card" />
            </div>
            <div className={classes.selectedCardInfo}>
                <CardDetails listing={listing} />
            </div>
        </div>
    </div>;
}

export function CardDetails(props: { listing?: IListing; }) {
    if (!props.listing) {
        return null;
    }

    const { card } = props.listing;
    return <div className={classes.content}>
        <div className={classes.name}>{card?.name}</div>
        <hr />
        <div className={classes.seriesGroup}>
            <span className={classes.series}>{card?.series}</span>
            <span className={classes.buyItNowPrice}>{props.listing.buyItNowPrice}</span>
        </div>
        <div className={classes.setGroup}>
            <span className={classes.set}>{card?.set}</span>
            <span className={classes.shippingCost}>{(props.listing.shipping?.domesticShippingCharge || props.listing.shipping?.internationalShippingCharge)?.toLocaleString('en', { minimumFractionDigits: 2 }) || '--.--'}</span>
        </div>
        <div className={classes.shippingType}>{props.listing.shipping?.shippingType || 'Domestic'}</div>

        <div className={classes.rarity}>
            <RemoteImage imgProps={{ style: { width: 10, height: 10 } }} imageName={card?.rarity} path='pokemon/rarity' /> {card?.rarity}
        </div>
    </div >;
}