import React from 'react';
import userAssetClasses from './user-asset.module.scss';
import { RainbowButton } from '../../components/shared/Button';
import { toCurrency } from '../../utils/utilities';
import { IAsset } from './IAsset';

export interface IAction {
  name: string;
  disabled?: boolean;
  icon?: JSX.Element;
  iconProps?: any;
  variant?: any;
  action?: (args?: string) => void;
  hide?: boolean;
  props?: any;
}
export function UserAsset(props: { className?: string; asset: IAsset; actions: IAction[]; showTracking?: boolean; }) {
  return (
    <user-asset class={userAssetClasses.userAsset}>
      <div className={userAssetClasses.content}>
        <img src={props.asset.imageUrl}></img>
        <div className={userAssetClasses.assetInfo}>
          <span className={userAssetClasses.line1}>{props.asset.line1}</span>
          <span className={userAssetClasses.line2}>{props.asset.line2}</span>
          <span className={userAssetClasses.line3}>{props.asset.line3}</span>
          <span className={userAssetClasses.line4}>{props.asset.line4}</span>
        </div>
        <div className={userAssetClasses.assetInfo2}>
          <div className={userAssetClasses.purchasePrice}>
            <span className={userAssetClasses.price}>Purchase Price</span>
            <span className={userAssetClasses.price}>{toCurrency(props.asset.price)}</span>
          </div>
          <div className={userAssetClasses.purchaseDate}>
            <span className={userAssetClasses.amount}>Purchase Date</span>
            <span className={userAssetClasses.purchaseDate}>{props.asset.purchaseDate}</span>
          </div>
        </div>

        {props.showTracking && (
          <div className={userAssetClasses.assetInfo3}>
            <div className={userAssetClasses.trackingNumber}>
              <span className={userAssetClasses.number}>Tracking Number</span>
              <span className={userAssetClasses.trackingNumber}>{props.asset.trackingNumber}</span>
            </div>
            <div className={userAssetClasses.trackingCompany}>
              <span className={userAssetClasses.tCompany}>Tracking Company</span>
              <span className={userAssetClasses.trackingCompany}>{props.asset.trackingCompany}</span>
            </div>
          </div>
        )}
      </div>
      <div className={userAssetClasses.actions}>
        {props.actions.map((action, index) => {
          const Icon = () => action.icon ?? <>{action.name}</>;
          return (!action.hide &&
            <RainbowButton
              disabled={action.disabled}
              key={index}
              variant={action.variant ?? 'secondary-outline'}
              onClick={() => action.action?.(props.asset.id)}
              {...action.props}
            >
              <Icon {...action.iconProps} />
            </RainbowButton>
          );
        })}
      </div>
    </user-asset>
  );
}
