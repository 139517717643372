/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Section } from './Section';
import { ActiveRadioGroup } from '../../components/shared/RadioButton';
import '../../styles/material.css';
import classes from '../../styles/shipping.module.scss';
import { useAppDispatch, useAppSelector } from '../../hook';
import { IListing } from '../../models/create-listing/iListing';
import { IShipping } from '../../models/create-listing/IShipping';
import { selectCurrentListing } from './createListingSelectors';

import { Heading } from '../../components/shared/Heading';
import { Information, Instruction } from '../../components/shared/Instruction';
import { InputAdornment, TextField } from '@mui/material';
import { setShipping } from './store/createListingSlice';
import { IInput, CustomInputComponent } from '../../components/input/FormatComponent';
import { Translation } from 'react-i18next';
import { AddressSearch, AddressSearchResult } from '../../components/address/AddressSearch';


export enum ShippingType {
  domestic = 'Domestic',
  domesticInternational = 'Domestic & International',
}

const shippingOptions = [
  { value: ShippingType.domestic, label: ShippingType.domestic, id: ShippingType.domestic, count: 0 },
  {
    value: ShippingType.domesticInternational,
    label: ShippingType.domesticInternational,
    id: ShippingType.domesticInternational,
    count: 0,
  },
];

export enum ShippingChargeType {
  freePostage = 'Free Postage',
  chargePostage = 'Charge Postage',
}

const shippingChargeOptions = [
  {
    value: ShippingChargeType.freePostage,
    label: ShippingChargeType.freePostage,
    id: ShippingChargeType.freePostage,
    count: 0,
  },
  {
    value: ShippingChargeType.chargePostage,
    label: ShippingChargeType.chargePostage,
    id: ShippingChargeType.chargePostage,
    count: 0,
  },
];

export function Shipping() {
  const dispatch = useAppDispatch();
  const currentListing: IListing = useAppSelector(selectCurrentListing);

  const [shippingTracked, setShippingTracked] = useState<boolean>(currentListing.shipping?.isTracked ?? false);

  const [shippingType, setShippingType] = useState<ShippingType | undefined>(
    currentListing.shipping?.shippingType ?? undefined
  );
  const [shippingChargeType, setShippingChargeType] = useState<ShippingChargeType | undefined>(
    currentListing.shipping?.shippingChargeType ?? undefined
  );

  const [domesticShippingCharge, setDomesticShippingCharge] = useState<number | undefined>(
    currentListing.shipping?.domesticShippingCharge ?? undefined
  );
  const [internationalShippingCharge, setInternationalShippingCharge] = useState<number | undefined>(
    currentListing.shipping?.internationalShippingCharge ?? undefined
  );

  const [itemAddress, setItemAddress] = useState<AddressSearchResult | undefined>(
    currentListing.shipping?.address
  );


  const onSelected = (shipping: IShipping) => dispatch(setShipping(shipping));


  const onShippingTrackedChanged = (isTracked: boolean) => {
    setShippingTracked(isTracked);
    dispatch(setShipping({ ...currentListing.shipping, isTracked: isTracked }));
  };

  const onAddressChanged = (address: AddressSearchResult) => {
    setItemAddress(address);
  };

  useEffect(() => {
    onSelected({
      shippingType: shippingType,
      shippingChargeType: shippingChargeType,
      domesticShippingCharge: domesticShippingCharge,
      internationalShippingCharge: internationalShippingCharge,
      address: itemAddress,
    });
  }, [shippingType, shippingChargeType, domesticShippingCharge, internationalShippingCharge, itemAddress]);

  return (
    <Section
      title={<Translation>{(t) => <>{t('createListing.shipping.postage')}</>}</Translation>}
      subTitle={<Translation>{(t) => <>{t('Shipping Options')}</>}</Translation>}
      className={classes.shipping}
    >
      <div className={classes.addressSearchContainer}>
        <Information text="Item Location" color='white' />
        <AddressSearch onSelected={onAddressChanged} value={itemAddress} />
        <Information text="We only use your country and postcode for distance calculations." size='small' />
      </div>
      <ShippingDestination shippingType={shippingType} onSelected={setShippingType} />
      <ShippingChargeOptions
        show={!!shippingType}
        shippingChargeType={shippingChargeType}
        onSelected={setShippingChargeType}
      />

      <ShippingCost
        show={shippingChargeType === ShippingChargeType.chargePostage}
        shippingType={shippingType}
        shippingChargeType={shippingChargeType}
        domesticShippingCharge={domesticShippingCharge}
        internationalShippingCharge={internationalShippingCharge}
        onDomesticShippingChanged={setDomesticShippingCharge}
        oninternationalShippingChanged={setInternationalShippingCharge}
      />
      <ShippingTracked value={shippingTracked} onSelected={onShippingTrackedChanged} title='Tracking' description='"Will you as the seller provide tracking"' />
    </Section>
  );
}

function ShippingDestination(props: { shippingType?: ShippingType; onSelected?: (value: ShippingType) => void; }) {
  const [shippingType, setShippingType] = useState<ShippingType | undefined>(props.shippingType ?? undefined);

  return (
    <>
      <Instruction text="Please select where are you willing to ship to" />
      <ActiveRadioGroup
        row
        aria-labelledby="condition-options"
        aria-orientation="horizontal"
        name="condition"
        value={shippingType || ''}
        onChange={(event) => {
          setShippingType(event.target.value as ShippingType);
          props.onSelected?.(event.target.value as ShippingType);
        }}
        options={shippingOptions}
      />
    </>
  );
}


export function ShippingTracked(props: { show?: boolean; value?: boolean, title: string, showTitle?: boolean, description: string, onSelected?: (value: boolean) => void; }) {
  return <>
    <Heading text={props.title}></Heading>
    <Instruction text={props.description} />
    <ActiveRadioGroup
      row
      aria-labelledby="shipping-charge-options"
      aria-orientation="horizontal"
      name="shipping-charge-options"
      value={props.value}
      onChange={(event) => {
        props.onSelected?.(event.target.value === 'true');
      }}
      options={[{ value: true, label: 'Yes', id: 'yes', count: 0 }, { value: false, label: 'No', id: 'no', count: 0 }]}
    />

  </>;
}

function ShippingChargeOptions(props: {
  show?: boolean;
  shippingChargeType?: ShippingChargeType;
  onSelected?: (value: ShippingChargeType) => void;
}) {
  return (
    <>
      {props.show ? (
        <>
          <Heading text="Cost"></Heading>
          <Instruction text="How will postage be handled?" />
          <ActiveRadioGroup
            row
            aria-labelledby="shipping-charge-options"
            aria-orientation="horizontal"
            name="shipping-charge-options"
            value={props.shippingChargeType || ''}
            onChange={(event) => {
              props.onSelected?.(event.target.value as ShippingChargeType);
            }}
            options={shippingChargeOptions}
          />

        </>
      ) : (
        <></>
      )}
    </>
  );
}

interface IShippingCostProps {
  shippingChargeType?: ShippingChargeType;
  shippingType?: ShippingType;
  domesticShippingCharge?: number;
  internationalShippingCharge?: number;
  show?: boolean;
  onDomesticShippingChanged?: (value?: number) => void;
  oninternationalShippingChanged?: (value?: number) => void;
}

function ShippingCost(props: IShippingCostProps) {
  const [dmCharge, setDmShippingCharge] = useState<string>(props.domesticShippingCharge?.toString() ?? '');
  const [intlCharge, setIntlCharge] = useState<string>(props.domesticShippingCharge?.toString() ?? '');

  useEffect(() => {
    props.onDomesticShippingChanged?.(parseFloat(dmCharge));
    props.oninternationalShippingChanged?.(parseFloat(intlCharge));
  }, [dmCharge, intlCharge]);

  useEffect(() => {
    if (props.shippingChargeType === ShippingChargeType.freePostage) {
      setDmShippingCharge('');
      setIntlCharge('');
      props.onDomesticShippingChanged?.(undefined);
      props.oninternationalShippingChanged?.(undefined);
    }
  }, [props.shippingChargeType]);

  useEffect(() => {
    setDmShippingCharge('');
    setIntlCharge('');
    props.onDomesticShippingChanged?.(undefined);
    props.oninternationalShippingChanged?.(undefined);
  }, [props.shippingType]);

  return (
    <>
      {props.show ? (
        <>
          <Heading text="Postage Amount" style={{ marginBottom: 5 }} ></Heading>
          <TextField
            error={true}
            required
            label="Domestic Postage"
            variant="outlined"
            placeholder="0.00"
            value={dmCharge}
            InputLabelProps={{ style: { color: '#ffffff80' } }}
            className={classes.domesticPostageAmount}
            onChange={(e) => {
              setDmShippingCharge(e.target.value);
            }}
            helperText=""
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{ color: 'blue' }}>
                  <span style={{ color: '#ffffff40' }}>$</span>
                </InputAdornment>
              ),
              inputComponent: CustomInputComponent,
              inputProps: {
                component: IInput,
              },
            }}
          />
          <div style={{ marginBottom: 5 }}></div>

          <TextField
            error={true}
            required
            label="International Postage"
            variant="outlined"
            value={intlCharge}
            placeholder={props.shippingType !== ShippingType.domesticInternational ? '' : '0.00'}
            disabled={props.shippingType !== ShippingType.domesticInternational}
            InputLabelProps={{ style: { color: '#ffffff80' } }}
            className={classes.internationalPostageAmount}
            onChange={(e) => {
              setIntlCharge(e.target.value);
            }}
            helperText="Enter your international postage price"
            style={{ width: 300, borderColor: 'white', outline: 'white' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <span style={{ color: '#ffffff40' }}>
                    {props.shippingType !== ShippingType.domesticInternational ? 'N/A' : '$'}
                  </span>
                </InputAdornment>
              ),
              inputComponent: CustomInputComponent,
              inputProps: {
                component: IInput,
              },
            }}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
