
import React from 'react';
import { Section } from './Section';
import { CardGradingCompany } from './CardGradingCompany';
import { ActiveRadioGroup } from '../../components/shared/RadioButton';
import { selfAssessmentGrades } from '../../data/grades';
import { ISubgrade } from '../../models/create-listing/ISubgrade';
import { Grade } from '../../models/create-listing/Grade';
import { ToggleButtonGroup } from '../../components/shared/ToggleButtonGroup';
import { Instruction } from '../../components/shared/Instruction';
import { CardGradingMobile } from './CardGradingMobile';
import { IOption } from '../../components/shared/AutoComplete';
import { ConditionType } from './models/ConditionType';
import classes from '../../styles/cardCondition-mobile.module.scss';



const conditionTypeOptions = [
  { value: ConditionType.Graded, label: ConditionType.Graded, id: ConditionType.Graded, count: 0 },
  { value: ConditionType.Ungraded, label: ConditionType.Ungraded, id: ConditionType.Ungraded, count: 0 }
];

const selfAssesmentOptions = selfAssessmentGrades.map(i => ({ id: `self-assess-${i.value}`, label: i.title, value: i.value, count: 0, description: i.description } as IOption));

export interface ICardConditionProps {
  selectedCardCondition?: ConditionType;
  selectedGradingCompany?: string;
  selectedCardGrade?: Grade;
  selectedOtherGradingCompanyName?: string;
  selectedSubgrades?: ISubgrade;
  selectedHasSubgrades?: boolean;
  onClearCardGrade?: () => void;
  onClearSubGrades?: () => void;
  onConditionSelected?: (condition: ConditionType) => void;
  onGradingCompanySelected?: (gc: string) => void;
  onCardGradeSelected?: (cg: Grade) => void;
  onOtherGradingCompanySelected?: (gc: string) => void;
  onSubgradeSelected?: (subgrades: ISubgrade) => void;
  onHasSubGradesSelected?: (hasSubGrades: boolean) => void;
}


export function CardConditionMobile(props: ICardConditionProps) {

  const onSubgradeSelected = (key: string, value?: number) => {
    const sGrades = { ...props.selectedSubgrades, [key]: value };
    props.onSubgradeSelected?.(sGrades);
  };

  const onHasSubGradesSelected = (hasSubGrades: boolean) => {
    props.onHasSubGradesSelected?.(hasSubGrades);
    !props.selectedHasSubgrades && props.onClearSubGrades?.();

  };


  const onSelfAssessmentGradeSelected = (grade: number) => {
    const cg = selfAssessmentGrades.find(i => i.value === grade);

    if (!cg) {
      throw new Error('Grades cannot be found for self assessment');
    }

    props.onCardGradeSelected?.(cg);

  };





  return (
    <Section
      title=""
      subTitle="Condition Type"
      className={classes.container}
    >
      <Instruction text="Select one of the two condition types" italic />
      <ToggleButtonGroup
        aria-labelledby="condition-options"
        aria-orientation="horizontal"
        value={props.selectedCardCondition ?? ''}
        onChange={(event) => {
          props.onConditionSelected?.(event.target.value as ConditionType);
        }}
        options={conditionTypeOptions}
      />

      {props.selectedCardCondition !== ConditionType.Unknown ?
        props.selectedCardCondition === ConditionType.Graded ?
          <>
            <CardGradingCompany
              orientation='vertical'
              onSelected={props.onGradingCompanySelected}
              selected={props.selectedGradingCompany}
              onOtherSet={props.onOtherGradingCompanySelected}
              other={props.selectedOtherGradingCompanyName} />


            {props.selectedGradingCompany && <CardGradingMobile
              selectedGradingCompany={props.selectedGradingCompany}
              selectedGrade={props.selectedCardGrade}
              onGradeSelected={props.onCardGradeSelected}
              selectedSubgrade={props.selectedSubgrades}
              hasSubgrades={props.selectedHasSubgrades}
              onSubgradeSelected={onSubgradeSelected}
              onHasSubGradesSelected={onHasSubGradesSelected} />}

          </>
          : <>
            <Instruction text="Before self assessing your card, take the time to review what each option means." italic />
            <ActiveRadioGroup
              onChange={(e) => onSelfAssessmentGradeSelected(+e.target.value)}
              value={props.selectedCardGrade?.value || ''}
              options={selfAssesmentOptions}
              className={classes.radio}
            />
          </>
        : <></>
      }
    </Section >
  );
}
