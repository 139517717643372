import React, { useEffect, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup, RadioGroupProps } from '@mui/material';
import { IOption } from './AutoComplete';


export interface RadioButtonProps {
    value: unknown;
    style?: React.CSSProperties;
    label: string;
    className?: string;
    checked?: boolean;
}

export function ActiveRadioGroup(props: RadioGroupProps & { options: IOption[]; }) {
    useEffect(() => {
        // no op
    }, [props.value]);

    return <RadioGroup {...props} style={{ rowGap: 10 }} className={props.className ?? ''}>
        {props.options.map((option: IOption, index) =>
            <RadioButton
                key={`${option.value}_${index}`}
                value={option.value}
                label={option.label}
                className={`${(props.className ?? '')} ${option.value === props.value ? 'selected' : ''}`}
                checked={option.value === props.value} />)}
        <>
            {props.children}

        </>
    </RadioGroup>;
}



export function RadioButton(props: RadioButtonProps) {

    const { checked } = props;
    const [checkedColor, setCheckedColor] = useState('#FFFFFF40');

    useEffect(() => {
        setCheckedColor(checked ? '#98FFF9' : '#FFFFFF40');
    }, [checked]);

    return <FormControlLabel
        {...props}
        style={{ color: checked ? 'white' : '#FFFFFF40', ...props.style }}
        control={
            <Radio style={{ color: checkedColor }} size='small' />}
        className={props.className}
        label={<span style={{ ...props.style, height: '100%', display: 'block' }} >{props.label}</span>}
    />;



}
