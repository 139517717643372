import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import { LoadingWidget } from '../../components/shared/Loading';
import { CardConditionPage } from '../../features/createListing/pages/web/CardConditionPage';
import { CardDescriptionPage } from '../../features/createListing/pages/web/CardDescriptionPage';
import { CardGradingCompanyPage } from '../../features/createListing/pages/web/CardGradingCompanyPage';
import { CardImageUploadPage } from '../../features/createListing/pages/web/CardImageUploadPage';
import { CardListingTypePage } from '../../features/createListing/pages/web/CardListingTypePage';
import { CreateListingPreviewPage } from '../../features/createListing/pages/web/CardPreviewPage';
import { CardSearchPage } from '../../features/createListing/pages/web/CardSearchPage';
import { CardShippingPage } from '../../features/createListing/pages/web/CardShippingPage';
import { CardTransactionPage } from '../../features/createListing/pages/web/CardTransactionPage';
import { RegisterPaymentService } from '../../features/user/PaymentRegistrationStatus';
import ProtectedRoute from '../../ProtectedRoute';
import { routeConfig } from '../../routes/routeConfig';
import CreateListing from './CreateListing';
import CreateListingMobile from './CreateListingMobile';
import useMedia from 'react-use/lib/useMedia';
import { ListingTitle } from '../../features/createListing/ListingTitle';
import { ListingTagsPage } from '../../features/createListing/ListingTags';

export function CreateListingRoutes() {
  const isMobile = useMedia('(max-width: 800px)');
  const CreateListingPage = isMobile ? CreateListingMobile : CreateListing;

  return (
    <Route
      path={routeConfig.createListing.path}
      element={
        <Suspense fallback={<LoadingWidget isLoading />}>
          <ProtectedRoute component={CreateListingPage} />
        </Suspense>
      }
    >
      <Route path="/create-listing/listing-type" element={<CardListingTypePage />} />
      <Route path="/create-listing/title" element={<ListingTitle />} />
      <Route path="/create-listing/tags" element={<ListingTagsPage />} />
      <Route path="/create-listing/search" element={<CardSearchPage />} />
      <Route path="/create-listing/condition" element={<CardConditionPage />}></Route>
      <Route path="/create-listing/grading-company" element={<CardGradingCompanyPage />} />
      <Route path="/create-listing/upload-images" element={<CardImageUploadPage />} />
      <Route path="/create-listing/register-payment-service" element={<RegisterPaymentService />} />
      <Route path="/create-listing/description" element={<CardDescriptionPage />} />
      <Route path="/create-listing/shipping" element={<CardShippingPage />} />
      <Route path="/create-listing/transaction" element={<CardTransactionPage />} />
      <Route path="/create-listing/preview" element={<CreateListingPreviewPage />} />
    </Route>
  );
}
