import React from 'react';
import { useEffect, useState } from 'react';

export function useIntersectionObserver(elementRef: React.RefObject<HTMLElement>, isLoading: boolean, callback: () => void) {

    const [itemRef] = useState(elementRef);

    useEffect(() => {
        if (itemRef.current) {
            const targetNode = itemRef.current;

            const intersectionObserver = new IntersectionObserver((entries) => {
                entries.forEach(async (entry) => {
                    if (entry.isIntersecting && !isLoading) {
                        await callback();
                    }
                });
            }
                // , { threshold: 0 }
            );

            intersectionObserver.observe(targetNode);

            return () => {
                intersectionObserver.unobserve(targetNode);
            };
        }
    }, [itemRef]);
}
