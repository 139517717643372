import React, { useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CommonMetaTags, SEOTags } from '../../HelmetSEO';
import { useAppDispatch, useAppSelector } from '../../hook';
import { ReactComponent as Condition } from '../../assets/svg/filter/condition_white.svg';
import { ReactComponent as Geographic } from '../../assets/svg/user-profile/geoicon.svg';
import { ReactComponent as Fav } from '../../assets/svg/user-profile/fav.svg';
import { ReactComponent as Ellipsis } from '../../assets/svg/user-profile/ellipsis.svg';
import { ReactComponent as Numbersign } from '../../assets/svg/user-profile/numbersign.svg';
import { ReactComponent as Company } from '../../assets/svg/user-profile/company.svg';
import { RemoteImage } from '../../features/cardSearch/RemoteImage';
import { getMarketAnalyticsSnapshot, getListing, getMarketAnalyticsSummary } from './listingActions';
import { selectListing } from './listingSelectors';
import { Skeleton } from '../../styles/components/Skeleton';
import classes from '../../styles/listing-page.module.scss';
import styles from '../../styles/App.module.scss';
import { ImagePanel } from '../../components/ImagePanel';
import { SelfAssess } from '../../data/grades';
import { toCurrency } from '../../utils/utilities';
import { IGetMarketAnalyticsParameters } from '../../services/listing-service/listing-service';
import { RainbowButton, RainbowInput } from '../../components/shared/Button';
import { routeConfig } from '../../routes/routeConfig';
import Divider from '@mui/material/Divider';
import { EmailButton } from '../../ExpandingButtonWhite';
import { ActionMenu, ActionOption } from '../../components/NavMenu';
import { getSellerListings, reportListing, reportSeller } from '../user/seller/sellerActions';
import { UserName } from '../../components/UserNameStyle';
import { MarketAnalysis } from './MarketAnalysis';
import { ListingType } from '../../components/tiles/Tiles';
import { ITagItem } from '../createListing/ListingTags';
import { SelectedTags } from '../createListing/SelectedTags';
import { ConfirmDialog } from '../user/ConfirmDialog';
import { ImageCarousel } from '../../components/shared/carousel/ImageCarousel';
import { useMedia } from 'react-use';
import { ImageDialog } from '../../components/ImageDialog';
import { ActiveRadioGroup } from '../../components/shared/RadioButton';
import { MenuItem } from '@mui/material';
import { useShippingLocation } from 'src/components/shipping/ShippingCharge';
import { useAuthHook } from 'src/shared/hooks/useAuthHook';
import { selectSellerListings } from '../user/seller/sellerSelector';
import { ListingSearchResultItem } from 'src/components/SearchResultItem';
import { ISearchResultItem } from 'src/models/iSearchResultItem';

export enum ReportListing {
  seller = 'Seller',
  listing = 'Listing',
}

export function Listing() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [dialogProps, setDialogProps] = React.useState<{ type: ReportListing, title: string, message: string; notes?: string; } | undefined>();
  const listing = useAppSelector(selectListing);
  const [areYouSuredialogOpen, setAreYouSureDialogOpen] = React.useState(false);
  const [selectedReportReason, setSelectedReportReason] = React.useState('');
  const [selectedReportReasonOther, setSelectedReportReasonOther] = React.useState('');
  const isMobile = useMedia('(max-width: 800px)');
  const { shippingCharge, getShippingCharge } = useShippingLocation(listing?.data?.country ?? '', listing?.data?.internationalShippingAmount ?? 0, listing?.data?.domesticShippingAmount ?? 0);
  const { data } = useAppSelector(selectSellerListings);
  const { user } = useAuthHook();
  const containerRef = useRef<HTMLDivElement>(null);
  const [meta, setMeta] = React.useState<{ name: string; content: string; }[]>(
    [] as { name: string; content: string; }[]
  );

  const openAreYouSureDialog = (type: ReportListing) => {
    setDialogProps({
      type: type,
      title: type === ReportListing.seller ? 'Report Seller' : 'Report Listing',
      message: `This will report the ${type} to Midnight Merchant, are you sure?`
    });
    setAreYouSureDialogOpen(true);
  };

  useEffect(() => {

    if (listing?.data?.emailAddress) {
      dispatch(getSellerListings({ page: 0, userId: listing.data.userId }));
    }

  }, [listing?.data?.emailAddress]);

  const isSold = listing?.data?.paymentDetails?.purchaseDate;

  const closeAreYouSure = () => {
    setAreYouSureDialogOpen(false);
  };

  const confirmDelete = () => {
    closeAreYouSure();

    if (dialogProps?.type === ReportListing.seller)
      reportBadSeller();
    else if (dialogProps?.type === ReportListing.listing)
      reportBadListing();

    setDialogProps(undefined);
  };

  const reportBadSeller = () => {
    dispatch(reportSeller({ listingId: listing?.data?.id, reason: [selectedReportReason, selectedReportReasonOther].filter(i => i != '').join(':') }));
  };

  const [fullScreenImage, setFullScreenImage] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(0);

  const reportBadListing = () => {
    dispatch(reportListing({ listingId: listing?.data?.id, reason: [selectedReportReason, selectedReportReasonOther].filter(i => i != '').join(':') }));
  };

  useEffect(() => {
    const path = decodeURI(location.pathname)
      .split('/')
      .filter((_, indx) => indx > 1); // remove the first two elements
    dispatch(getListing({ listingId: path[0] })).then((res: any) => {
      if (res.error) {
        navigate(routeConfig.listing.notFound);
      }
    });

  }, [location.pathname]);

  useEffect(() => {
    if (listing && !listing?.isLoading && listing?.data?.card?.cardId) {
      const grade = [SelfAssess, 'assess', ''].includes(listing.data?.grade?.grade.name ?? '')
        ? 0
        : Math.floor(listing.data?.grade?.grade.value ?? 0);  // round down to the nearest whole number, no half grades in the market analytics currently

      const options = {
        cardId: listing?.data?.card?.cardId,
        cardGrade: grade,
        period: 'snapshot',
      } as IGetMarketAnalyticsParameters;
      dispatch(getMarketAnalyticsSnapshot(options));
      dispatch(getMarketAnalyticsSummary(options));
    }
  }, [listing?.data?.card?.cardId]);

  useEffect(() => {
    if (listing) {
      const metaArray: { name: string; content: string; }[] = [
        { name: 'name', content: listing?.data?.name ?? '' },
        { name: 'series', content: listing?.data?.series ?? '' },
        { name: 'set', content: listing?.data?.set ?? '' },
        { name: 'number', content: listing?.data?.number ?? '' },
        { name: 'illustrator', content: listing?.data?.illustrator ?? '' },
        { name: 'rarity', content: listing?.data?.rarity ?? '' },
        { name: 'element_type', content: listing?.data?.elementType ?? '' },
        { name: 'price', content: listing?.data?.buyItNowPrice?.toString() ?? '' },
      ];
      setMeta(metaArray);
    }
  }, [listing]);

  const thumbnailImageList = useMemo(() => {
    if (listing) {
      return Object.keys(listing?.data?.images ?? []).map((key) => {
        return `${process.env.REACT_APP_IMAGES_URL}/userImages/thumbnails/${listing.data?.images?.[key].name}`;
      });
    }
    return [];
  }, [listing?.data?.images]);

  const imageList = useMemo(() => {
    if (listing) {
      return Object.keys(listing?.data?.images ?? []).map((key) => {
        return `${process.env.REACT_APP_IMAGES_URL}/userImages/draft/${listing.data?.images?.[key].name}`;
      });
    }
    return [];
  }, [listing?.data?.images]);

  const SellerListings = useMemo(() =>
    <div className={classes.sellerListings}>
      <div className={classes.title}>
        Other Listings From This Seller
      </div>
      <div className={classes.content}>
        {
          Array.isArray(data?.records) && data.records.filter(i => i.id !== listing?.data?.id).map(
            (item: any, index: number) =>
              <div key={index} className={classes.sellerItem}>
                <ListingSearchResultItem item={{
                  series: item.series,
                  set: item.set,
                  name: item.name,
                  number: item.number,
                  rarity: item.rarity,
                  imageList: item.imageList,
                  id: item.id,
                  buyItNowPrice: item.buyItNowPrice,
                  price: item.buyItNowPrice,
                  sellerAvatar: item.sellerAvatar,
                  userId: item.userId,
                  seller: item.emailAddress,
                  shippingCost: getShippingCharge(item.country, item.internationalShippingCharge, item.domesticShippingCharge)
                } as ISearchResultItem} onSelected={() => containerRef.current?.scrollIntoView()}></ListingSearchResultItem>
              </div>
          )
        }
      </div>
    </div>
    , [data]);


  const tags = useMemo(() => {
    if (listing?.data?.listingType !== ListingType.mixedBundle)
      return;

    return <SelectedTags selectedItems={listing?.data?.tags?.map(i => ({ title: i, value: i } as ITagItem)) ?? []} />;


  }, [data]);

  const cardDetails = useMemo(() => {
    return listing?.data?.listingType === ListingType.singleCard ?
      <div className={classes.cardDetailsAndConditionGroup}>
        <div className={classes.seriesname}>{listing?.data?.card?.series}</div>
        <div className={classes.cardNumber}>
          <Skeleton skelType="txtArea" loading={listing?.isLoading ?? false}>
            <React.Fragment>
              <Numbersign className={classes.numbersign} />
              <span>{listing?.data?.card?.number?.replace('#', '')}</span>
            </React.Fragment>
          </Skeleton>
        </div>
        <div className={classes.condition}>
          <Skeleton skelType="txtArea" loading={listing?.isLoading ?? false}>
            <>
              <Condition className={classes.conditionicon} />
              <span>{listing?.data?.grade?.grade?.title ?? 0}</span>
            </>
          </Skeleton>
        </div>
        <div className={classes.setNameDetails}>{listing?.data?.card?.set}</div>
        <div className={classes.rarity}>
          <span><RemoteImage imgProps={{ style: { height: 18, width: 18, display: 'inline-block', verticalAlign: -2 } }} imageName={listing?.data?.card?.rarity ?? ''} path="pokemon/rarity" /> {' '} </span>
          <div className={classes.rarityText}>{listing?.data?.card?.rarity}</div>
        </div>

        <div className={classes.gradecompany}>
          <Company className={classes.companysign} />
          <Skeleton skelType="txtArea" loading={listing?.isLoading ?? false}>
            <span>{listing?.data?.grade?.grade?.company}</span>
          </Skeleton>
        </div>
      </div>
      : null;
  }, [listing?.data?.listingType]);

  return (
    <div className={classes.content} ref={containerRef}>

      <SEOTags position={11}>
        <CommonMetaTags
          title='Midnight Merchant'
          description={listing?.data?.description ?? 'Midnight Merchant - TCG Marketplace'}
          keywords={['Midnight Merchant', 'Search Results', listing?.data?.series, listing?.data?.set, listing?.data?.title, listing?.data?.card?.name]}
          amount={listing?.data?.buyItNowPrice ?? '0'}
          imageUrl={imageList[0]}
          targetType={'product'} />
        <>{meta.map(e => <meta key={e.name} name={e.name} content={e.content} />)}</>
      </SEOTags>
      <div className={classes.listing}>
        <div className={classes.leftContainer}>
          <div className={classes.imageContainer}>
            <Skeleton skelType="img" loading={listing?.isLoading ?? false}>
              <ImageCarousel images={thumbnailImageList} showBullets={isMobile} showThumbs={!isMobile} isMobile={isMobile}
                onImageClick={(index: number) => {
                  setFullScreenImage(true);
                  setSelectedImage(index);
                }}
              />
            </Skeleton>
          </div>
        </div>
        <div className={classes.details}>

          {isSold && <div className={classes.sold}>Item Sold</div>}
          <div className={classes.background}>
            <div className={classes.title}>
              <Skeleton skelType="txt" loading={listing?.isLoading ?? false}>
                <div>
                  <div className={classes.name}>{listing?.data?.card?.name}</div>
                </div>
              </Skeleton>
              <div>
                <div className={classes.price}>
                  <Skeleton skelType="txt" loading={listing?.isLoading ?? false}>
                    <>{toCurrency(+(listing?.data?.buyItNowPrice ?? 0))}</>
                  </Skeleton>
                </div>
              </div>
            </div>
            <div className={classes.userNameShipping}>
              <div className={classes.userName} >
                <Skeleton skelType="avatar" loading={listing?.isLoading ?? false}>
                  <UserName name={listing?.data?.emailAddress?.substring(0, listing?.data?.emailAddress.indexOf('@')) ?? ''} avatar={listing?.data?.sellerAvatar} />
                </Skeleton>
              </div>
              <div className={classes.shipping}>
                <Skeleton skelType="txt" loading={listing?.isLoading ?? false}>
                  <div className={classes.shippingAccent}>
                    {`+${toCurrency(shippingCharge)} Shipping`}
                  </div>
                </Skeleton>
              </div>
            </div>
            <div className={classes.actionBar}>
              <div className={classes.location}>
                <Geographic style={{ height: 20, width: 20 }} />
                <span>{listing?.data?.country}</span>
              </div>
              <div className={classes.divider}></div>
              <div className={classes.buttonBar}>
                <a aria-label='link' className={classes.emailButton} href={`mailto:${listing?.data?.emailAddress}`} target='_blank' rel='noreferrer' title="Contact Seller">
                  <EmailButton label="" />
                </a>
                <button aria-label="favourite" className={classes.favButton}>
                  <div className={classes.circle}>
                    <Fav className={classes.fav} />
                  </div>
                </button>
                <ActionMenu
                  variant='context'
                  options={[
                    {
                      label: 'Report Seller',
                      value: 'report-seller',
                      icon: <></>,
                    },
                    {
                      label: 'Report Listing',
                      value: 'report-listing',
                      icon: <></>,
                    }
                  ]}
                  onOptionSelected={function (option: ActionOption): void {
                    switch (option.value) {
                      case 'report-seller':
                        openAreYouSureDialog(ReportListing.seller);
                        break;
                      case 'report-listing':
                        openAreYouSureDialog(ReportListing.listing);
                        break;
                      default:
                        break;
                    }
                  }}
                  triggerElement={
                    <button aria-label="other options" className={classes.ellipsisButton} style={{ cursor: 'pointer' }}>
                      <div className={classes.circle}>
                        <Ellipsis className={classes.ellipsis} />
                      </div>
                    </button>}>
                  {listing?.data?.emailAddress == user?.email && !isSold && <>
                    <hr></hr>
                    <MenuItem>
                      <a aria-label='link' onClick={() => navigate(`/edit/${listing?.data?.id}`)}>Edit my listing</a>
                    </MenuItem>
                  </>
                  }
                </ActionMenu>
                {!isSold && <Skeleton loading={listing?.isLoading ?? false} skelType="btn" style={{ width: 120, height: 25 }}>
                  <RainbowButton style={{ width: 120 }} onClick={() => navigate(routeConfig.listing.buyNow)}>
                    <span style={{ fontSize: 12, fontWeight: 500, letterSpacing: 1.25 }}>BUY NOW</span>
                  </RainbowButton>
                </Skeleton>}
              </div>
            </div>
            <Divider style={{ border: '0.5px solid #505659' }} />
            {cardDetails}
            {tags}
            <Divider style={{ border: '1px solid #505659' }} />
            <div className={classes.description}>
              <Skeleton skelType="txtArea" loading={listing?.isLoading ?? false}>
                <>
                  <span>Description</span>
                  <div className={classes.content}>
                    {listing?.data?.description}
                  </div>
                </>
              </Skeleton>
            </div>
            <Divider style={{ border: '1px solid #505659' }} />

            <Skeleton skelType="txtArea" loading={listing?.isLoading ?? false}>
              {listing?.data?.listingType === ListingType.singleCard ?
                <MarketAnalysis />
                : <></>
              }
            </Skeleton>
          </div>
        </div >
        {SellerListings}
      </div >
      <div className={classes.leftd}>
        <ImagePanel className={styles.imagePanel} />
      </div >
      <ConfirmDialog
        open={areYouSuredialogOpen}
        onClose={closeAreYouSure}
        onConfirm={confirmDelete}
        message={dialogProps?.message}
        title={dialogProps?.title}>
        <ActiveRadioGroup
          onChange={(event) => {
            setSelectedReportReason(event.target.value);
            setSelectedReportReasonOther('');
          }}
          value={selectedReportReason}
          aria-labelledby="reason-options"
          aria-orientation="vertical"
          name="reason-options"
          options={[
            { label: 'Listing is Spam', value: 'spam', count: 0, id: '0' },
            { label: 'Fake listing', value: 'fake', count: 0, id: '1' },
            { label: 'Card image mismatch', value: 'mismatch', count: 0, id: '2' },
            { label: 'Offensive language', value: 'offensive', count: 0, id: '3' },
            { label: 'Inappropriate title', value: 'inapproptiate_title', count: 0, id: '4' },
            { label: 'Report Abuse', value: 'abuse', count: 0, id: '5' },

            { label: 'Report Other', value: 'other', count: 0, id: '99' }]} />

        {selectedReportReason == 'other' && <RainbowInput style={{ height: 60 }}
          variant='outlined'
          value={selectedReportReasonOther}
          onChange={(event) => {
            setSelectedReportReasonOther(event.target.value);
          }}
        />}
      </ConfirmDialog>
      <ImageDialog
        selectedImage={selectedImage}
        selectedImageChanged={(index: number) => {
          setSelectedImage(index);
        }}
        imageList={imageList}
        show={fullScreenImage}
        onClose={() => {
          setFullScreenImage(false);
        }} />

    </div>
  );
}

