import React, { useEffect, useMemo } from 'react';
import { TumbleWeed } from '../../components/shared/TumbleWeed';
import { UserPageHeader } from './components/UserPageHeader';
import classes from './my-listings.module.scss';
import { UserAsset } from './UserAsset';
import { ReactComponent as SellersHub } from '../../assets/svg/bottombar/marketplace.svg';
import { ReactComponent as BinIcon } from '../../assets/svg/bin.svg';
import { useAppDispatch, useAppSelector } from '../../hook';
import { LoadingWidget } from '../../components/shared/Loading';
import { useNavigate } from 'react-router-dom';
import { deleteListing } from '../listing/listingActions';
import { ConfirmDialog } from './ConfirmDialog';
import { IListing } from 'src/models/create-listing/iListing';
import { useEffectOnce } from 'react-use';
import { getSellerListings } from './seller/sellerActions';
import { selectSellerListings } from './seller/sellerSelector';
import { LoadMore } from 'src/components/LoadMore';
import { useAuthHook } from 'src/shared/hooks/useAuthHook';

export function MyListings() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [areYouSuredialogOpen, setAreYouSuredialogOpen] = React.useState(false);
  const [deletedListingId, setDeletedListingId] = React.useState<string>();
  const [listings, setListings] = React.useState<IListing[]>([]);
  const pageStart = React.useRef(0);
  const { data, isLoading } = useAppSelector(selectSellerListings);
  const { user } = useAuthHook();

  useEffectOnce(() => {
    dispatch(getSellerListings({ page: 0, userId: btoa(user?.email ?? '') }));
  });

  useEffect(() => {
    if (data?.records) {
      pageStart.current = data.start + 20;
      setListings([...listings, ...data.records]);
    }
  }, [data]);

  const openAreYouSureDialog = (listingId: string) => {
    setDeletedListingId(listingId);
    setAreYouSuredialogOpen(true);
  };

  const closeAreYouSure = () => {
    setAreYouSuredialogOpen(false);
    setDeletedListingId(undefined);
  };

  const confirmDelete = () => {
    if (deletedListingId) {
      dispatch(deleteListing(deletedListingId));
      closeAreYouSure();
    }
    else
      throw new Error('deletedListingId is undefined');
  };


  const assets = useMemo(() => {

    const currentListings = listings.sort((a, b) => {
      if (a.ts < b.ts) return 1;
      if (a.ts > b.ts) return -1;
      return 0;

    }).map((listing: IListing) => {
      const img = listing.imageList?.[0];
      return {
        asset: {
          id: listing.id,
          imageUrl: `${process.env.REACT_APP_IMAGES_URL}/userImages/thumbnails/${img.name}`,
          line1: listing.name ?? listing.title,
          line2: listing.set,
          line3: listing.rarity,
          line4: listing.number,
          price: +listing.buyItNowPrice,
          purchaseDate: '',
        },
        actions: [
          {
            name: 'Edit',
            action: () => navigate(`/edit/${listing.id}/${listing.countryCode ?? 'AU'}`),
          },
          {
            name: 'Remove Listing',
            icon: <BinIcon fill='#FF0000' />,
            iconProps: { className: classes.binIcon },
            props: {
              style: { fill: '#FF0000' }
            },
            variant: 'dangerous',
            action: async () => {
              openAreYouSureDialog(listing.id);
            },
          },

        ],
      };
    });

    return currentListings;
  }, [listings]);


  const loadMore = () => {
    const userId = btoa(user?.email ?? '');
    if (userId === undefined) {
      throw new Error('userId is undefined');
    }

    dispatch(getSellerListings({ page: pageStart.current, userId }));
  };

  return (
    <div className={classes.myListings}>
      <UserPageHeader
        title="My Current Listings"
        pageTitle="My Listings"
        icon={<SellersHub height={30} width={30} />}
      />
      <LoadingWidget isLoading={isLoading && listings?.length <= 0} style={{ marginTop: 40, height: 100 }}>
        {!isLoading && !listings?.length && listings?.length <= 0 && <TumbleWeed message="List a collectible! Then edit it here!" />}
        {assets.map((asset: { asset: any, actions: any; }, index: number) => (
          <React.Fragment key={index}>
            <UserAsset key={index} asset={asset.asset} actions={asset.actions}></UserAsset>
          </React.Fragment>
        ))}
      </LoadingWidget>
      <ConfirmDialog
        open={areYouSuredialogOpen}
        onClose={closeAreYouSure}
        onConfirm={confirmDelete}
        title='Delete Listing'
        notes='Please note, the listing might take a a minute or two to dissappear from your listings page'
        message='Are you sure you want to delete this listing?'
      />
      {data.records?.length > 0 &&
        <div className={classes.loadMoreButtonContainer}>
          <LoadMore isLoading={isLoading} hasMore={true}
            loadMore={() => {
              loadMore();
            }} ></LoadMore>
        </div>
      }
    </div>
  );
}


