import React from 'react';


import { useAppDispatch, useAppSelector } from '../../../../hook';
import { useMedia } from 'react-use';

import { ISubgrade } from '../../../../models/create-listing/ISubgrade';
import { Grade } from '../../../../models/create-listing/Grade';
import { CardCondition } from '../../CardCondition';
import { ConditionType } from '../../models/ConditionType';
import { CardConditionMobile } from '../../CardConditionMobile';
import { selectCurrentListing } from '../../createListingSelectors';
import { setCardCondition, clearCardSubgrades, setCardGrade, setCardSubgrades, setCardHasSubGrades, setOtherGradingCompany, setCardGradingCompany } from '../../store/createListingSlice';

export function CardConditionPage() {
    const isMobile = useMedia('(max-width: 800px)');
    const dispatch = useAppDispatch();

    const currentListing = useAppSelector(selectCurrentListing);

    const onConditionSelected = (condition: ConditionType) => {
        dispatch(setCardCondition(condition));
    };

    const onGradingCompanySelected = (gc: string) => {
        dispatch(setCardGradingCompany(gc));
    };

    const onCardGradeSelected = (cg: Grade) => {
        dispatch(setCardGrade(cg));
    };

    const onClearSubGrades = () => {
        dispatch(clearCardSubgrades());
    };

    const onOtherGradingCompanySelected = (otherGradingCompany: string) => {
        dispatch(setOtherGradingCompany(otherGradingCompany));
    };

    const onSubgradeSelected = (subGrades: ISubgrade) => {
        dispatch(setCardSubgrades(subGrades));
    };

    const onHasSubGradesSelected = (hasSubGrades: boolean) => {
        dispatch(setCardHasSubGrades(hasSubGrades));
    };


    const Condition = isMobile ? CardConditionMobile : CardCondition;

    return <Condition onClearSubGrades={onClearSubGrades}
        onConditionSelected={onConditionSelected}
        onGradingCompanySelected={onGradingCompanySelected}
        onCardGradeSelected={onCardGradeSelected}
        onOtherGradingCompanySelected={onOtherGradingCompanySelected}
        onSubgradeSelected={onSubgradeSelected}
        onHasSubGradesSelected={onHasSubGradesSelected}
        selectedCardCondition={currentListing.graded}
        selectedGradingCompany={currentListing.grade?.grade?.company}
        selectedCardGrade={currentListing.grade?.grade}
        selectedOtherGradingCompanyName={currentListing.grade?.otherGradingCompany}
        selectedSubgrades={currentListing.grade?.grade?.subgrades}
        selectedHasSubgrades={currentListing.grade?.hasSubgrades}
    />;
}


