import React from 'react';
import { Heading } from '../../../components/shared/Heading';

export function UserPageHeader(props: { className?: string; title: string; pageTitle: string; icon?: any; }) {
  const Icon = () => props.icon;
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Heading color="#98FFF9">
        <div className="flex-row-left flex-column-gap-5">
          <Icon />
          {props.pageTitle}
        </div>
      </Heading>
      <div>
        <div style={{ padding: '0 0 10px 0', display: 'inline-block', lineHeight: 4 }}>
          <Heading color="white" text={props.title} />
        </div>
        <hr />
      </div>
    </div>
  );
}
