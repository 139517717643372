import React from 'react';
import classes from '../../styles/card-search-results.module.scss';
import { ReactComponent as Rectangle } from '../../assets/svg/Rectangle.svg';
import { ReactComponent as Remove } from '../../assets/svg/remove.svg';
import { ICard } from '../createListing/models/iCard';
import { Typography } from '@mui/material';
import { selectCardSearchInProgress } from '../createListing/createListingSelectors';
import { useAppDispatch, useAppSelector } from '../../hook';
import { RemoteImage } from './RemoteImage';
import { LoadingWidget } from '../../components/shared/Loading';
import { useRecentSearch } from 'src/components/SearchBar/CardSearchBar';
import { setCardsSearchString } from './cardSearchSlice';
import { searchCardsAction } from './cardActions';

export function CardSearchResults(props: {
  searchResults: Array<ICard>;
  className?: string;
  selected: ICard | undefined;
  onCardSelected?: (value: ICard) => void;
  onCardAdded?: (value: ICard) => void;
  isLoading?: boolean;
}) {
  const { searchResults } = props;
  const loading = useAppSelector<boolean>(selectCardSearchInProgress); // TODO hmmm this could be local state?
  const dispatch = useAppDispatch();
  // card added was introduced when the thought of adding multiple cards was raised. this is still undecided
  const onCardAdded = (card: ICard) => props.onCardAdded?.(card);
  const { removeRecentSearchItem, searchItems } = useRecentSearch();
  const isCardSelected = (card1: ICard | undefined, card2: ICard | undefined) => {
    if (card1?.id === card2?.id) {
      return true;
    }
    return false;
  };


  return (<div id="search-results-container" style={{ position: 'relative' }}>
    <LoadingWidget isLoading={loading} />
    <div style={loading ? { opacity: 0.5 } : undefined}
      className={`${classes.placeAdSearchResultsContainer} ${props.className ?? ''} `}
    >
      {searchResults.length === 0 && !loading && <div className={classes.beforeSearch}>
        {searchItems.length > 0 && <div className={classes.recentSearch}>
          Recent Searches
          <ul>
            {searchItems.filter(i => i).map((i, index) => (
              <li key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><a aria-label='link' style={{ cursor: 'pointer', padding: '3px 0' }}
                onClick={() => {
                  dispatch(setCardsSearchString(i));
                  dispatch(searchCardsAction());
                }}>{i}</a> <Remove style={{ minWidth: 20 }} onClick={() => removeRecentSearchItem(i)} />
              </li>
            ))}
          </ul>
        </div>}
        <div className={classes.trySearches}>
          Try Searches
          <ul>
            <li>Base Set</li>
            <li>Gym Heroes</li>
            <li>Build a Bear your dream experience</li>
            <li>Crocknaw Heart Gold</li>
            <li>Fighting Resistance</li>
          </ul>
        </div>
      </div>}

      {searchResults.map((i, index) => (
        <div
          key={index}
          className={`${classes.cardContainer} ${isCardSelected(props.selected, i) ? classes.selected : ''}`}
          onClick={() => onCardAdded(i)}
        >
          <div className={classes.cardContainer2}>
            <div className={classes.image}>
              <img src={i.imageSrc?.[0]} alt="" />
            </div>
            <div className={classes.meta}>
              <div className={classes.title}>
                {i.name}
              </div>
              <div className={classes.number}>
                <Rectangle style={{ stroke: 'white', width: 15, marginLeft: 5 }} /> <span>{i.number}</span>
              </div>
              <div className={classes.set}>
                <RemoteImage imageName={i.set} path='pokemon/sets' />
                <span>{i.set}</span>
              </div>
              <div className={classes.rarity}>
                <RemoteImage imageName={i.rarity.replace(' ', '-').toLowerCase()} path='pokemon/rarity' />
                {i.rarity}
              </div>
              <div className={classes.series}>
                <img src={`${process.env.REACT_APP_REMOTE_ASSETS}/pokemon/set.svg`}></img>
                {i.series}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
    <Typography style={{ fontSize: 12, padding: 12, fontStyle: 'italic' }}>
      Not seeing your card?, try modify your search criteria. If you still dont see it reach out to us on <a aria-label='link' href={process.env.REACT_APP_DISCORD_URL} target='blank'>discord.</a>
    </Typography>
  </div >
  );
}