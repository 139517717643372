import React from 'react';


const remoteUrl = process.env.REACT_APP_REMOTE_ASSETS;

export function RemoteImage(props: { imageName: string, path: string, imgProps?: React.ImgHTMLAttributes<HTMLImageElement> }) {


    return props.imageName
        ? <img {...props.imgProps} src={`${remoteUrl}/${props.path}/${props.imageName?.toLowerCase().replace(/\s/g, '-').toLowerCase()}.svg`} alt="?"></img>
        : null;
}
